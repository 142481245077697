import React from "react"
import moment from "moment"
import { Link } from "gatsby"
import classNames from "classnames"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import { GATSBY_WEBSITE_URL, GATSBY_CONTACT_NUMBER } from "gatsby-env-variables"

import styles from "./utils/layout.module.scss"
import { isBrowser } from "../../services/general"

const Notification = ({ isHome }) => {
  let dateToday = moment().format("YYYY-MM-DD")

  const { loading, error, data } = useQuery(
    gql`
      query TodaysDate($holiday_date: date!, $domain: jsonb) {
        holiday(
          where: {
            holiday_date: { _eq: $holiday_date }
            domains: { _contains: $domain }
          }
        ) {
          name
          holiday_date
          short_message
        }
      }
    `,
    {
      variables: {
        holiday_date: dateToday,
        domain: GATSBY_WEBSITE_URL,
      },
    }
  )

  if (loading) return null
  if (error) console.log(error)

  let notificationMessage =
    data.holiday === undefined || data.holiday.length === 0
      ? null
      : data.holiday[0].short_message

  let url = ""
  if (isBrowser()) url = window.location.href

  if (url.includes("giotrif"))
    notificationMessage = (
      <span className={classNames(styles["notificationMessage"])}>
        {notificationMessage || ""} We’re happy to answer your questions. Get
        answers from our <Link to="/giotrif/help-center">Help Center</Link> or{" "}
        <Link to="/giotrif/help-center/#contact-us">contact us</Link> for
        assistance.
      </span>
    )
  else
    notificationMessage = (
      <span className={classNames(styles["notificationMessage"])}>
        {notificationMessage || ""} We’re happy to answer your questions. Get
        answers from our <Link to="/help-center">Help Center</Link> or contact
        us at{" "}
        <a href={`tel:${GATSBY_CONTACT_NUMBER}`}>{GATSBY_CONTACT_NUMBER}</a>.
      </span>
    )

  if( isHome ) notificationMessage = null

  if (notificationMessage)
    return (
      <div
        className={classNames(
          "notification py-1 is-warning",
          styles["notification"]
        )}
      >
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
